import { useEffect, useState } from "react";
import { useForm } from "@refinedev/mantine";
import { IconPencil, IconX, IconCheckbox, IconSquareRoundedCheck } from "@tabler/icons";
import {
  Button,
  Stack,
  Chip,
  Textarea,
  Paper,
  Group,
  Avatar,
  Text,
  Divider,
  ScrollArea,

} from "@mantine/core";
import dayjs from "dayjs";
import { useGetIdentity } from "@refinedev/core";
import { createStyles } from "@mantine/styles";
// import _ from "lodash";

import { datePickerValue, isAffectrixClientId, isVenousClientId } from 'utilities';

const formatDate = (x: any) => dayjs(x).format('MM-DD-YY');

export const Notes = (props:any) => {

  const {
    data,
    data: {
      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {},
      authed_user = {}
    } = {}
  } = useGetIdentity<any>({
      // v3LegacyAuthProviderCompatible: true
  });

  const isUserOfAffectrix = isAffectrixClientId(clientId);
  const isUserOfVenous = isVenousClientId(clientId);

  const { 
    patient_id,
    forms,
    forms: {
      main: {
        values = null,
        setFieldValue = null
      } = {}
    } = {},
    main_accessor='',
    referrals = [],
    patient_notes = [],
    chip_settings = {},
    is_rep = false,
    edit_referral_id
  } = props;

  const referralsOfPatient = referrals?.map( (x:any) => {
    // const {
    //   // id,
    //   product_type: {
    //     // title = ''
    //   } = {},
    //   // rx_received_date
    // } = x;
    const id = x?.id;
    const title = x?.product_type?.title;
    const rx_received_date = x?.rx_received_date;
    return {
      id,
      title: `${title} ${formatDate(rx_received_date)}`,
      rx_received_date
    }
  // @ts-ignore
  }).reduce( (prev, curr) => {
    const { id } = curr;
    // @ts-ignore
    const exists = prev.some( x => x.id == id);
    if (!exists){
      prev.push(curr);
    }
    return prev;
  }, []);

  const referralsOfPatientIntoChips = referralsOfPatient
    // @ts-ignore
    .sort(({rx_received_date: a}, {rx_received_date: b}) => {
        const aDay = dayjs(a);
        const bDay = dayjs(b);
        if (aDay.isAfter(bDay)){
            return -1
        }
        if (aDay.isBefore(bDay)){
            return 1
        }
        return 0
    })
    // @ts-ignore
    .map( ({id,title}) => 
    <Chip value={id} key={id}>
      {title}
    </Chip>);

  const {
    noteTypes: noteTypesFromProps
  } = chip_settings;

  // TODO probably remove this jank
  let noteTypes: any[] = noteTypesFromProps?.length > 0 ? 
  noteTypesFromProps : [
    { id: 'COMM', title: 'Communication' },
    { id: 'VER', title: 'Verification' },
    { id: 'BILL', title: 'Billing' },     
  ];

  const noteTags = !is_rep ?[
    { id: 'ALERT', title: 'Alert' },
    { id: 'REP_ALERT', title: 'Rep Alert' }
  ] : [
    { id: 'REP_ALERT', title: 'Rep Alert' }
  ];

  const noteTypesOfChips = noteTypes.map(({id, title}) => <Chip value={id}>
    {title}
  </Chip>);

  const noteTagsOfChips = noteTags.map(({id, title}) => <Chip value={id}>
    {title}
  </Chip>);

  const transformedNoteType = noteTypes
  // default selected chip
  .filter(({id}) => {
    const bools = [
      isUserOfAffectrix && id == 'VER',
      isUserOfVenous && id == 'COMM'
    ];
    return bools.some( x => x)
  })
  .map(({id}) => id)
  .reduce( (prev,curr) => {
    prev.push(curr);
    return prev;
  }, [] as any);

  const transformedNoteTags = noteTags
  // default selected chip
  .map(({id}) => id)
  .reduce( (prev,curr) => {
    prev.push(curr);
    return prev;
  }, [] as any);
  ////

  const [ noteFilter, setNoteFilter ] = useState({

    // multiple chips may be selected
    note_tags: [],

    // multiple chip may be selected
    note_type: [],//transformedNoteType,

    // multiple chips may be selected
    // @ts-ignore
    referral_tags: []// referralsOfPatient.map(({id}) => id)
  });

  const [ once, setOnce ] = useState(false);

  const [ prevEditRefId, setPrevEditRefId ] = useState('');

  // may be able to fix this?
  useEffect( () => {
    if (!once && referralsOfPatient.length > 0){
      setNoteFilter( prev => {
        let newState = {
          ...prev,
          referral_tags: referralsOfPatient.map(({id}:any) => id),
          // TODO remove jank
          note_type: transformedNoteType.length == 0 ? ['COMM'] : transformedNoteType,
          note_tags: is_rep ? [] : transformedNoteTags
        }; 
        return newState
      });
      setOnce(true);
    }
    
    if (edit_referral_id && prevEditRefId != edit_referral_id){
      // @ts-ignore
      setNoteFilter( prev => ({
        ...prev,
        referral_tags: [ edit_referral_id ]
      }));
      setPrevEditRefId(edit_referral_id);
    }
    // TODO move over
    // setFieldValueUponPatientNotes('note.data.metadata.note_type',transformedNoteType);
  }, [props]);

  const [ patientNotes, setPatientNotes ] = useState(patient_notes);
  useEffect(() => {
    setPatientNotes(patient_notes);
  }, [patient_notes]);

  const {
    values: ackNoteValues,
    setValues: setValuesUponAckNote,
    saveButtonProps: ackNoteSaveButtonProps,
    reset: resetOfAckNote,
    refineCore: {
      id: idOfAckNote,
      setId: setIdOfAckNote
    }
  } = useForm({
    refineCoreProps: {
      onMutationSuccess: ({data}, variables, context) => {
        const { id: idOfThisNote } = data;
        // @ts-ignore
        const patientNoteIndex = values?.[main_accessor]?.findIndex( x => {
          const { note: {id: idOfSomeNote}} = x;
          return idOfSomeNote == idOfThisNote
        } );
        if (patientNoteIndex > -1){
          // TODO parameterize
          setFieldValue(`${main_accessor}.${patientNoteIndex}.note`, data);
        };

        // const [ patientNotes, setPatientNotes ] = useState(patient_notes);
        setPatientNotes( (prev:any) => {
          const mapped = prev.map( (x:any) => {
            const { note: {id: idOfSomeNote}} = x;
            if (idOfSomeNote == idOfThisNote){
              return {
                ...prev,
                note: data
              }
            } else return x;
          });
          return mapped;
        })

        // setEditNote({
        //   ...editNote,
        //   // @ts-ignore
        //   [idOfThisNote]: false
        // });
        // //then setFieldValue for that note
        // editNoteReset()
      },
      action: "edit",
      resource: "note",
      redirect: false,
      // queryOptions: {
      //   enabled: !!idOfAckNote
      // },
      metaData: {
        fields: [
          'id',
          'content',
          'created_at',
          {
            authed_user: [
              'id',
              'display_name',
              'photoURL'
            ]
          },
          'metadata'
        ]
      }
    }
  })

  const [ editNote, setEditNote ] = useState({});

  const {
    values: editNoteValues,
    setValues: setValuesUponEditNote,
    getInputProps: getInputPropsForEditNote,
    saveButtonProps: editNoteSaveButtonProps,
    reset: editNoteReset,
    setFieldValue: setFieldValueUponEditNote,
    refineCore:{
      id: idOfEditNote,
      setId: setIdOfEditNote,
    },
  } = useForm({
      initialValues: {
          content: '',
          metadata: {
            note_type: [], // either 'COMM' or 'VERIFICATION'
            note_tags: [],  // alert or rep alert
            referral_tags: [],
            acknowledgement: {}
          }
          // uid,
              // }
          // },
          // product_type_id: null
      },
      refineCoreProps:{
          onMutationSuccess: ({data}, variables, context) => {
              const { id: idOfThisNote } = data;
              // @ts-ignore
              const patientNoteIndex = values?.[main_accessor]?.findIndex( x => {
                const { note: {id: idOfSomeNote}} = x;
                return idOfSomeNote == idOfThisNote
              } );
              if (patientNoteIndex > -1){
                // TODO parameterize
                setFieldValue(`${main_accessor}.${patientNoteIndex}.note`, data);
              };

              // const [ patientNotes, setPatientNotes ] = useState(patient_notes);
              setPatientNotes( (prev:any) => {
                const mapped = prev.map( (x:any) => {
                  const { note: {id: idOfSomeNote}} = x;
                  if (idOfSomeNote == idOfThisNote){
                    return {
                      ...prev,
                      note: data
                    }
                  } else return x;
                });
                return mapped;
              })

              setEditNote({
                ...editNote,
                // @ts-ignore
                [idOfThisNote]: false
              });
              //then setFieldValue for that note
              editNoteReset()
          },
          action: "edit",
          resource: "note",
          redirect: false,
          // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
          metaData: {
              fields: [
                  // {
                  //     note: [
                        'id',
                        'content',
                        'created_at',
                        {
                          authed_user: [
                            'id',
                            'display_name',
                            'photoURL'
                          ]
                        },
                        'metadata'
                        // {
                        //   note_type: ['code']
                        // },
                  //     ]
                  // }

              ]
          }
      },
  });

  const useStyles = createStyles((theme) => ({
    comment: {
      padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
    },
  
    body: {
    //   paddingLeft: rem(54),
    //   paddingTop: theme.spacing.sm,
      padding: theme.spacing.xs,
      fontSize: theme.fontSizes.sm,
    },
  
    content: {
      '& > p:last-child': {
        marginBottom: 0,
      },
    },
  }));

  const { classes } = useStyles();

  /////
  const notesOfPatient = patientNotes?.map( (thing:any) => {
    const {
        note: {
          id,
            content,
            created_at,
            authed_user: {
              id: uid,
                display_name,
                photoURL
            },
            metadata
            // note_type: {
            //     code
            // }
        }
    } = thing;
    return {
        id,
        uid,
        content,
        created_at,
        display_name,
        photoURL,
        metadata
    }
  })
  // @ts-ignore
  .filter( thing => {
    const {
      metadata
    } = thing;

    const {
      referral_tags: mRefTags
    } = metadata;

    const {
      referral_tags: fRefTags
    } = noteFilter;

    let refTagsMaybeBoolean = null;
    if (fRefTags.length > 0){
      refTagsMaybeBoolean = fRefTags.some( x => 
        mRefTags.some( (y:string) => x == y )
      )
    }

    const shouldInclude = [
      refTagsMaybeBoolean
    ]
    .filter( x => x != null)
    .some( x => x);

    return shouldInclude;
  })

  // @ts-ignore
  .filter( thing => {
      const {
        metadata
      } = thing;

      const {
        note_tags: fNoteTags,
        note_type: fNoteType,
        referral_tags: fRefTags
      } = noteFilter;

      const {
        note_tags: mNoteTags,
        note_type: mNotetype,
      } = metadata;

      let noteTagMaybeBoolean = null;
      if (fNoteTags.length > 0){
        noteTagMaybeBoolean = fNoteTags.some( x => 
          mNoteTags.some( (y:string) => x == y )
        );
      }

      let noteTypeMaybeBoolean = null;
      if (fNoteType.length > 0){
        noteTypeMaybeBoolean = fNoteType.some( x => 
          mNotetype.some( (y:string) => x == y )
        )
      }

      const shouldInclude = [
        noteTagMaybeBoolean,
        noteTypeMaybeBoolean,
      ]
      .filter( x => x != null)
      .some( x => x);

      return shouldInclude;
  })
  // @ts-ignore
  .filter(thing => {
    const {
        metadata
    } = thing;

    const {
      note_tags: mNoteTags,
      note_type: mNotetype,
      referral_tags: mRefTags
    } = metadata;

    if (
      is_rep &&
      ( mRefTags.some( (y:string) => y == 'ALERT' ) &&
      mRefTags.length == 1 )
    ){
      return false
    }

    return true
  })
  // @ts-ignore
  .sort(({created_at: a}, {created_at: b}) => {
      const aDay = dayjs(a);
      const bDay = dayjs(b);
      if (aDay.isAfter(bDay)){
          return -1
      }
      if (aDay.isBefore(bDay)){
          return 1
      }
      return 0
  })
  // reduce
  // @ts-ignore
  .reduce( (prev,curr,i,arr) => {
    // if first iteration
    if ( i == 0 ){
      prev = {
        unacknowledgedAlerts: [],
        rest: []
      }
    }

    const { metadata } = curr;
    const acknowledgement = metadata?.acknowledgement || {};
    const note_tags = metadata?.note_tags || [];

    const isAcknowledged = Object.keys(acknowledgement).length > 0;
    const isAckowledgable = note_tags.length > 0 &&
    note_tags.some( (x:any) => x.includes('ALERT')) && 
      !isAcknowledged;

    if (isAckowledgable && !isAcknowledged){
      prev.unacknowledgedAlerts.push(curr);
    } else {
      prev.rest.push(curr);
    }

    //if last element
    if ((i + 1) == arr.length){
      return [...prev.unacknowledgedAlerts, ...prev.rest]
    } else {
      return prev
    }
  }, [])
  .map( (thing:any,index:number) => {
    const {
        content,
        created_at,
        display_name,
        photoURL,
        metadata
    } = thing;
    const formattedDate = dayjs(created_at).format('MM-DD-YY h:mm A');

    const { id: idOfNote, uid: uidOfNote } = thing;
    const { note_type = '', referral_tags = [], note_tags = [], acknowledgement = {} } = metadata;

    const isNoteOfTheCurrentUser = uidOfNote == uid;
    // @ts-ignore
    const isNotEditingThisNote = !!!editNote[idOfNote] || editNote[idOfNote] == false;
    const isEditingThisNote = !isNotEditingThisNote;

    const mappedReferralTags = referralsOfPatient
    .map( ({id,title}:any) => {
      const isVisible = referral_tags.includes(id) || isEditingThisNote;

      return <Chip 
        value={id} 
        key={id}
        style={{
          display: !isVisible ? 'none' : undefined
        }}
      >
        {title}
      </Chip>
    });

    const mappedNoteTags = noteTags
    .map( ({id,title}:any) => {
      const isVisible = note_tags.includes(id) || isEditingThisNote;

      return <Chip 
        value={id} 
        key={id}
        style={{
          display: !isVisible ? 'none' : undefined
        }}
      >
        {title}
      </Chip>
    });

    const mappedNoteTypes = noteTypes
    .map(({id, title}) => {
      const isVisible = note_type.includes(id) || isEditingThisNote;

      return <Chip 
        value={id} 
        key={id}
        style={{
          display: !isVisible ? 'none' : undefined
        }}
      >
        {title}
      </Chip>
    });

    const isAcknowledged = Object.keys(acknowledgement).length > 0;
    const isAckowledgable = note_tags.length > 0 &&
    note_tags.some( (x:any) => x.includes('ALERT')) && 
      !isAcknowledged;

    return (
        <Paper withBorder radius="lg" className={classes.comment}>
            <Group style={{padding:1}}>
                <Avatar src={photoURL} alt={display_name} radius="xl" />
                <>
                    <Text fz="sm">{display_name}</Text>
                    <Text fz="xs" c="dimmed" fw={700}>
                        {formattedDate}
                    </Text>
                </>
                {isNoteOfTheCurrentUser && <Button size='xs' onClick={ () => {
                  const allEditNotesOff = 
                    Object.keys(editNote)
                    .filter(x => x !== idOfNote)
                    .map( key => [key, false])
                  setEditNote({
                    ...Object.fromEntries(allEditNotesOff),
                    // @ts-ignore
                    [idOfNote]: !editNote[idOfNote]
                  });

                  // if currently editing this note, then clear edit not
                  if (isEditingThisNote){
                    setValuesUponEditNote({});
                  }
                  setIdOfEditNote(idOfNote);
                  setValuesUponEditNote({
                    content,
                    metadata
                  });
                }}>
                  {/* @ts-ignore */}
                  { isNotEditingThisNote ? <IconPencil></IconPencil> : <IconX></IconX>}
                </Button>}
                {/* transform metadata into chips */}
                {/* @ts-ignore */}
                { isEditingThisNote ? <>
                  <Chip.Group 
                    multiple
                    defaultValue={referral_tags}
                    {...getInputPropsForEditNote('metadata.referral_tags')}
                  >
                    {mappedReferralTags}
                  </Chip.Group>
                  <Chip.Group
                    multiple
                    defaultValue={note_type}
                    {...getInputPropsForEditNote('metadata.note_type')}
                  >
                    {mappedNoteTypes}
                  </Chip.Group>
                  <Chip.Group 
                    multiple
                    defaultValue={note_tags}
                    {...getInputPropsForEditNote('metadata.note_tags')}
                  >
                    {mappedNoteTags}
                  </Chip.Group>
                </> : <>
                  <Chip.Group multiple value={referral_tags}>
                    {mappedReferralTags}
                  </Chip.Group>
                  <Chip.Group multiple value={note_type}>
                    {mappedNoteTypes}
                  </Chip.Group>
                  <Chip.Group multiple value={note_tags}>
                    {mappedNoteTags}
                  </Chip.Group>
                </>}
                { (isAckowledgable && !isAcknowledged) && <div 
                onMouseOver={() => {
                  setIdOfAckNote(idOfNote);
                  setValuesUponAckNote({
                    metadata: {
                      ...metadata,
                      acknowledgement: {
                        uid,
                        acknowledgedOn: new Date(),
                        acknowledgedBy: authed_user?.display_name
                      }
                    }
                  });
                }}
                // onMouseOut={() => {
                //   setIdOfAckNote(undefined);
                //   resetOfAckNote();
                // }}
                >
                  <Button {...ackNoteSaveButtonProps} color="red" leftIcon={<IconSquareRoundedCheck/>}>ACK</Button>
                </div>}
                { (isAcknowledged && acknowledgement?.acknowledgedOn && acknowledgement?.acknowledgedBy) && <Text>
                  Acknowledged by {acknowledgement?.acknowledgedBy} at {dayjs(acknowledgement?.acknowledgedOn).format('MM-DD-YY h:mm A')}
                </Text>}
            </Group>
            {/* @ts-ignore */}
            { isNotEditingThisNote ? 
            <Text className={classes.body} size="sm">
                {content}
            </Text> : <Textarea spellCheck="true"
              {...getInputPropsForEditNote('content')}
            />
            }
            {/* <pre>{JSON.stringify(acknowledgement,null,2)}</pre> */}
            {/* @ts-ignore */}
            { editNote[idOfNote] == true ? <Button {...editNoteSaveButtonProps}>Submit Edit</Button> : <></>}
        </Paper>
    )
  });

  const extra = {
    referralsOfPatientIntoChips,
    noteTypesOfChips,
    noteTagsOfChips,
    referralsOfPatient
  };

  const things = useState(false);
  const [ noteVisibility, setNoteVisibility ] = things;
  const triggerNoteVisibility = () => setNoteVisibility(!noteVisibility);

  const addNoteSection = <AddNote
      patient_id={patient_id}
      uid={uid}
      forms={forms}
      extra={extra}
      main_accessor={main_accessor}
      things={things}
    />;

  return <>
    { !noteVisibility && <>
      <br/>
      <Group>
        Filter Notes By Tags{!is_rep && <> &nbsp;&nbsp;-OR-
        <>
          <Button onClick={triggerNoteVisibility}>Add Note</Button>
        </></>}
      </Group>
      <br/>
      <Group>
        <Chip.Group multiple 
          value={noteFilter.referral_tags}
          onChange={(e)=> {
            setNoteFilter({
              ...noteFilter,
              referral_tags: e as any
            })
          }}
        >
          {referralsOfPatientIntoChips}
        </Chip.Group>
        <Divider size="sm" orientation="vertical" />
        <Chip.Group
          multiple
          value={noteFilter.note_type}
          onChange={(e)=> {
            setNoteFilter({
              ...noteFilter,
              // @ts-ignore
              note_type: e as string[]
            })
          }}
        >
          {noteTypesOfChips}
        </Chip.Group>
        <Divider size="sm" orientation="vertical" />
        <Chip.Group multiple 
          value={noteFilter.note_tags}
          onChange={(e)=> {
            setNoteFilter({
              ...noteFilter,
              note_tags: e as any
            })
          }}
        >
          {noteTagsOfChips}
        </Chip.Group>
        <br/>
      </Group>
    </>}
    { (values && !is_rep) && <>
      <br/>
      {addNoteSection}
      <br/>
      <Divider></Divider>
    </>}
    { is_rep && <br/>}
    <ScrollArea style={{ height: 280 }}>
      {notesOfPatient}
    </ScrollArea>
  </>
};

export const AddNote = (props:any) => {

  const {
    forms: {
      main: {
        insertListItem
      }
    },
    patient_id,
    // uid = '',  // can not pass here since the updated value of uid is not beeing passed to this prop
    extra: {
      referralsOfPatientIntoChips,
      noteTypesOfChips,
      noteTagsOfChips,
      referralsOfPatient
    },
    main_accessor,
    things
  } = props;

  const {
    data,
    data: {
      claims: {
        "x-hasura-user-id": uid = '',
        "x-hasura-client-id": clientId = ''
      } = {},
      authed_user = {}
    } = {}
  } = useGetIdentity<any>({
      // v3LegacyAuthProviderCompatible: true
  });

  const isUserOfAffectrix = isAffectrixClientId(clientId);

  const note_type = isUserOfAffectrix ? ['VER'] : ['COMM'];

  const maybeSelectOneReferral = referralsOfPatient.length == 1 ? 
  referralsOfPatient.map(({id}:any) => id ) : [];

  let initialValues = {
    patient_id,
    note: {
        data: {
            content: '',
            uid,
            note_type_id: null,
            metadata: {
              note_type, // either 'COMM' or 'VERIFICATION'
              note_tags: [],  // alert or rep alert
              referral_tags: maybeSelectOneReferral,
              acknowledgement: {},
            }
        }
    },
    product_type_id: null
  };

  const {
    values: patientNoteValues,
    setValues: setPatientNoteValues,
    setFieldValue: setFieldValueUponPatientNotes,
    getInputProps: getInputPropsForPatientNote,
    onSubmit,
    saveButtonProps: patientNoteSaveButtonProps,
    reset
  } = useForm({
      initialValues: initialValues,
      refineCoreProps:{
          onMutationSuccess: ({data}, variables, context) => {
              // TODO parameterize
              insertListItem(`${main_accessor}`,data);
              setNoteVisibility(false);
              setPatientNoteValues(initialValues);
              // @ts-ignore
              setFieldValueUponPatientNotes('patient_id',patient_id);
              setFieldValueUponPatientNotes('note.data.uid', uid);  
          },
          action: "create",
          resource: "patient_note",
          redirect: false,
          // queryOptions: { enabled: initialValues?.patient_id?.length > 0 },
          metaData: {
              fields: [
                  {
                      note: [
                          'id',
                          'content',
                          'created_at',
                          {
                              authed_user: [
                                  'id',
                                  'display_name',
                                  'photoURL'
                              ]
                          },
                          'metadata'
                      ]
                  }

              ]
          }
      },
      validate: (values:any) => {
        const {
            note: {
                data: {
                    metadata,
                    content = ''
                }
            }
        } = values;

        // @ts-ignore
        const referral_tags = metadata?.referral_tags || [];
        // @ts-ignore
        const note_type = metadata?.note_type || '';

        return {
            'note.data.content': content.trim().length > 0 ? null : "Note cannot be empty.",
            'note.data.metadata.note_type': note_type.length > 0 ? null : 'Select some tags.',
            'note.data.metadata.referral_tags': referral_tags.length > 0 ? null : 'Select some tags.',
        }
    }
  });

  useEffect( () => {
    setPatientNoteValues(initialValues);
    setFieldValueUponPatientNotes('note.data.uid', uid);
    setFieldValueUponPatientNotes('patient_id', patient_id);
  }, [uid, patient_id]);

  const [ noteVisibility, setNoteVisibility ] = things;
  const triggerNoteVisibility = () => setNoteVisibility(!noteVisibility);

  return noteVisibility && <>
    Select the type(s) of note and associated referral(s)
    <br/>
    <br/>
    <Group>
      {/* note.metadata (note_type, product_type) */}
      <Chip.Group multiple
        {...getInputPropsForPatientNote('note.data.metadata.referral_tags')}
      >
        {referralsOfPatientIntoChips}
      </Chip.Group>
      <Divider size="sm" orientation="vertical" />
      <Chip.Group multiple
        {...getInputPropsForPatientNote('note.data.metadata.note_type')}
        // defaultValue={transformedNoteType}
      >
        {noteTypesOfChips}
      </Chip.Group>
      <Divider size="sm" orientation="vertical" />
      <Chip.Group multiple
        {...getInputPropsForPatientNote('note.data.metadata.note_tags')}
      >
        {noteTagsOfChips}
      </Chip.Group>
    </Group>
    <Textarea
        label="Content"
        spellCheck="true"
        placeholder="Add content.  Note is automatically timestamped and associated to you."
        {...getInputPropsForPatientNote('note.data.content')}
        minRows={4}
    >
    </Textarea>
    <br/>
    {/* @ts-ignore */}
    <Button {...patientNoteSaveButtonProps}>Submit Note</Button>
    &nbsp;
    <Button onClick={triggerNoteVisibility}>Cancel Note</Button>
  </>
};

export const EditNote = (props:any) => {

};