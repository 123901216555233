import { HttpError, useGetIdentity, useNavigation, useList, useGo } from "@refinedev/core";
import { Create, TextField, useForm, useStepsForm, SaveButton, useSelect } from "@refinedev/mantine";

import {
Box, Autocomplete, Stepper, //
TextInput, Select, Code, Group, Button, Space, ActionIcon, CloseButton, Text, Grid, Radio, Divider, Textarea
} from "@mantine/core";

// import { Controller, useForm } from "@pankod/refine-react-hook-form";
import dayjs from "dayjs";

import { DatePicker } from '@mantine/dates'
import { IFilterz } from "interfaces";
import { useEffect, useState } from "react";
// @ts-ignore
import { AdminDebug } from "utilities/index.tsx";
import { isAffectrixClientId, isVenousClientId } from "utilities";

export const ReferralCreate: React.FC = () => {

  const go = useGo();

	const onMutationSuccess = (data: any, variables: any, context: any) => {
    const { data:{id, patient_id} } = data;
    go({
      to: `/directory/patient/edit/${patient_id}`,
    });
    // edit(`operations/referral`,id)
		// list('operations/referral');
  }

  const {
    data: {
      // @ts-ignore

        claims: {
        // "x-hasura-user-id": uid,
        "x-hasura-client-id": client_id = ''
        } = {}
    } = {}
} = useGetIdentity({
      // v3LegacyAuthProviderCompatible: true
  });

    const {
        saveButtonProps,
        getInputProps, 
        errors,
        validate,
        steps: { currentStep, gotoStep },
        setFieldValue,
        values,
        insertListItem,
        removeListItem
    } = useStepsForm<IFilterz, HttpError>({
        refineCoreProps:{
          onMutationSuccess,
          redirect: false,
          resource: "referral",
          metaData: {
            fields: [
              'id',
              'patient_id'
            ]
          }
        },
        initialValues: {
            injury_sites: [],
            icd10_codes: [],
            client_id,

            patient_id: null,
            patient_status_id: null,
            insurance_id: null,
            insurance_status_id: null,
            provider_id: null,
            billing_provider_id: null,
            product_type_id: null,
            rx_length_id: null,
            claim_type_id: null,
            agreement: null
        },
        validate: (values:any) => {
          const {
            agreement,
            patient_id,
            patient_status_id,
            insurance_id,
            insurance_status_id,

            provider_id,

            product_title,
            product_type_id,
            rx_length_id,
            rx_date,
            injury_sites,
            icd10_codes,

            claim_type_id
          } = values;
          // validation for each step
          if (currentStep === 0) {
              return {
                // agreement: agreement?.length ? null : "Please select this.",
                patient_id: patient_id?.length ? null : "Please select a Patient.",
                patient_status_id: patient_status_id?.length ? null : "Please select a patient status.",
              };
          }
          if (currentStep === 1) {
            return {
              product_type_id: product_type_id?.length ? null : "Please select a product type",
              product_title: product_title?.length ? null : "Please select a product",
              // rx_length_id: rx_length_id?.length ? null : "Please select an Rx length.",
              // rx_date: rx_date ? null : "Please set the date of the Rx.",
              // injury_sites: injury_sites.length > 0 ? null : "Define at least one injury site.",
              // icd10_codes: icd10_codes.length > 0 ? null : "Define at least one ICD 10 code."
              //TODO 
            }
          }
          if (currentStep === 2) {
            if (referringProviderSectionValue != 'unknown'){
              return {
                provider_id: provider_id?.length ? null : "Please select a Provider, or specify that the provider is Unknown." 
              };
            } else {
              return {}
            }
          }
          if (currentStep === 3) {
            if (billingProviderSectionValue != 'unknown'){
              return {
                billing_provider_id: provider_id?.length ? null : "Please select a Provider, or specify that the provider is Unknown." 
              };
            } else {
              return {}
            }
          }
          
          if (currentStep === 4) {
            return {
              // claim_type_id: claim_type_id?.length ? null : "Please select a claim type.",
              insurance_id: insurance_id?.length ? null : "Please select an Insurance.",
              insurance_status_id: insurance_status_id?.length ? null : "Please select an insurance status."
            }
          }
          if (currentStep === 5) {
            return {
              claim_type_id: claim_type_id?.length ? null : "Please select a claim type.",
              // insurance_id: insurance_id?.length ? null : "Please select an Insurance.",
              // insurance_status_id: insurance_status_id?.length ? null : "Please select an insurance status."
            }
          }

          return {};
        },
        transformValues: (values) => {
          const {
            insurance_id
          } = values;
          values.bill_to_insurance_id = insurance_id;
          return values;
        }
    });

    const { selectProps: claimTypeProps } = useSelect({
      resource: 'claim_type',
      // defaultValue: undefined,
      optionLabel: 'title',

      meta: {
          fields: ['id', 'title'],
      },

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: insuranceProps } = useSelect({
      resource: 'insurance',

      // defaultValue: undefined,
      optionLabel: 'title',

      meta: {
          fields: ['id', 'title'],
      },

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: insuranceStatusSelectProps } = useSelect({
      resource: "insurance_status",

      // defaultValue: values?.insurance_status_id as BaseKey || '',
      optionLabel: 'title',

      meta: {
          fields: ["id", "title"],
      },

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: patientSelectProps } = useSelect({
      resource: 'patient',

      // defaultValue: undefined,
      optionLabel: 'name_and_dob',
      onSearch: (value) => [
          {
              field: "name_and_dob",
              operator: "contains",
              value,
          },
      ],

      meta: {
          fields: ['id', 'name_and_dob'],
      },

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: patientStatusSelectProps } = useSelect({
      resource: "patient_status",

      // defaultValue: values?.patient_status_id as BaseKey || '',
      optionLabel: 'title',

      meta: {
          fields: ["id", "title"],
      },

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: providerProps } = useSelect({
      resource: 'provider',

      // defaultValue: undefined,
      optionLabel: 'name',

      meta: {
          fields: ['id', 'name'],
      },
      filters:[
        {
          field: 'provider_representatives_aggregate.count.predicate',
          operator: 'gt',
          value: 0
        }
      ],

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: productTypeProps } = useSelect({
      resource: 'product_type',

      // defaultValue: undefined,
      optionLabel: 'title',

      meta: {
          fields: ['id', 'title'],
      },

      pagination: {
        mode: "server"
      }
    });

    const { selectProps: rxLengthProps } = useSelect({
      resource: 'rx_length',

      // defaultValue: undefined,
      optionLabel: 'title',

      meta: {
          fields: ['id', 'title'],
      },

      pagination: {
        mode: "server"
      }
    });

    const {
      data: {
        // @ts-ignore
        data: injurySiteLookup
      } = {}
    } = useList({
      resource: 'injury_site',

      meta:{
        fields: [ 'id', 'title'],
      },

      pagination: {
        pageSize: 1000
      }
    });

    // @ts-ignore
    const injurySites = values?.injury_sites?.map((item, index) => (
      <Group mt="xs">
        <Select
            mt={8}
            label="Injury Site"
            placeholder="Type in an Injury Site."
            {...getInputProps(`injury_sites.${index}`)}
            searchable={true}
            clearable={true}
            filterDataOnExactSearchMatch={true}
            data={injurySiteLookup?.map(({id,title}) => ({value:title, label:title})) as any}
            style={{ maxWidth: 300 }}
        />
        <ActionIcon color="red" onClick={() => removeListItem('injury_sites', index)}>
          {/* @ts-ignore */}
          <CloseButton size="1rem" />
        </ActionIcon>
      </Group>
    ));

    // @ts-ignore
    // const icd10_codes = values?.icd10_codes?.map((item, index) => (
    //     <Group mt="xs">
    //       <TextInput
    //           mt={8}
    //           label="ICD 10 code"
    //           placeholder="Type in a Code."
    //           {...getInputProps(`icd10_codes.${index}`)}
    //           // {...injurySiteProps}
    //           style={{ maxWidth: 300 }}
    //       />
    //       <ActionIcon color="red" onClick={() => removeListItem('icd10_codes', index)}>
    //         {/* @ts-ignore */}
    //         <CloseButton size="1rem" />
    //       </ActionIcon>
    //     </Group>
    //   ));

  const {
    data: {
      // @ts-ignore
      data: productTypeLookup = []
    } = {}
  } = useList({
    resource: 'product_type',

    meta:{
      fields: [ 'id', 'title'],
    },

    pagination: {
      pageSize: 1000
    }
  })

  const {
    data: {
      // @ts-ignore
      data: rxLengthLookup = []
    } = {}
  } = useList({
    resource: 'rx_length',

    meta:{
      fields: [ 'id', 'title'],
    },

    pagination: {
      pageSize: 1000
    }
  })

    useEffect( ()=> {
      let product_type='';
      const pt_matches = productTypeLookup?.filter( x => x.id == values?.product_type_id );
      if (pt_matches.length == 1){
        [{title: product_type}] = pt_matches;
      }

      let injury_site = '';
      if (values?.injury_sites){
        // @ts-ignore
        injury_site = values?.injury_sites.join()
      }

      let rx_length='';
      const rx_matches = rxLengthLookup?.filter( x => x.id == values?.rx_length_id);
      if (rx_matches.length == 1){
        [{title: rx_length}] = rx_matches;
      }
      values.product_title = [ product_type, injury_site, rx_length ].filter(x => x).join('-')
    }, [values]);

    // maybe part 1 of ghetto fix to fill select with newly created patient
    const [ ghettoPatient, setGhettoPatient ] = useState(undefined);

    // useForm for Patient Creation
    const {
      values: patientCreateValues,
      getInputProps: patientCreateGetInputProps,
      saveButtonProps: patientCreateSaveButtonProps,
      reset: resetOfPatientCreate,
      setFieldValue: setFieldValueUponPatientCreate
    } = useForm({
      initialValues: {
        client_id: '',
        date_of_birth_user_entered: ''
      },
      refineCoreProps: {
        onMutationSuccess({data}, variables, context) {
          //set field upon referral of patient ID
          const { id: patientId, name_and_dob } = data;
          setFieldValue('patient_id', patientId);
          values.patient_id = patientId;

          setGhettoPatient(name_and_dob);
          // console.log(ghettoPatient);

          // mutate some UI
          setPatientSectionValue('select');

          // move to next step
          gotoStep(1)
        },
        action: "create",
        resource: 'patient',
        redirect: false,
        metaData: {
          fields: [
            'id',
            'name_and_dob'
            // maybe more
          ]
        }
      },
      validate(values) {
        const {
          date_of_birth_user_entered,
          client_id
        } = values;

        // @ts-ignore
        const date = new Date(date_of_birth_user_entered);

        const [ aYear, aMonth, aDay ] = [
          String(Number(String(date.getFullYear()).slice(2,4))),
          String(date.getMonth() + 1),
          String(date.getDate())
        ];

        // @ts-ignore
        const [ bMonth, bDay, bYear ] = date_of_birth_user_entered
        // @ts-ignore
        .split('-').map( x => String(Number(x)));

        const isValidDate = aYear == bYear && aMonth == bMonth && aDay == bDay;

        return {
          date_of_birth_user_entered: isValidDate ? null : "Please enter a valid date in the format of MM-DD-YY.",
          client_id: client_id.length > 10 ? null : "Please select a client."
        }
      },
      // @ts-ignore
      transformValues(values) {
        let {
          date_of_birth_user_entered,
          ...rest
        } = values;

        // @ts-ignore
        rest.date_of_birth = new Date(date_of_birth_user_entered);

        // @ts-ignore
        rest.name = rest.name.toUpperCase()

        return rest;
      },
    })

  //   const { selectProps: clientSelectProps } = useSelect({
  //     resource: "clients",
  //     defaultValue: undefined,
  //     optionLabel: 'name',
  //     metaData: {
  //         fields: ["id", "name"],
  //     },
  // });

  const {
    data: {
      // @ts-ignore
      data: clients = []
    } = {}
  } = useList({
    resource: 'clients',

    meta:{
      fields: [ 'id', 'title'],
    },

    pagination: {
      pageSize: 1000
    }
  })

  const filteredClients = clients.reduce( (prev,x) => {
    const { id, title } = x;
    if (isAffectrixClientId(client_id)){
      prev.push({
        value: id,
        label: title
      });
    } else if (isVenousClientId(client_id)){
      // @ts-ignore
      if (!isAffectrixClientId(id)){
        prev.push({
          value: id,
          label: title
        });
      }
    }
    return prev;
  }, []);

  const agreements = [
    { label: 'VS', value: 'VS'},  
    { label: 'X', value: 'X'},
    // { label: 'Z', value: 'Z'}
  ].filter( x => {
    if (isAffectrixClientId(client_id)){
      return true
    } else if (isVenousClientId(client_id)){
      return ['VS'].includes(x.label)
    }
    return false
  });

    // Select Existing Patient OR
    // Create a New Patient
    const [ patientSectionValue, setPatientSectionValue ] = useState('select');
    const patientSection = <>
      <Select
          mt={8}
          label="Client"
          withAsterisk
          {...getInputProps('client_id')}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          data={filteredClients as any}
          style={{ maxWidth: 300 }}
      />
      <Select
        mt={8}
        label="Agreement"
        style={{ maxWidth: 300 }}
        {...getInputProps('agreement')}
        clearable
        data={agreements}
      />
      <Select
          mt={8}
          label="Patient Status"
          withAsterisk
          {...getInputProps('patient_status_id')}
          {...patientStatusSelectProps}
          style={{ maxWidth: 300 }}
      />
      <br/>
      <Divider variant="dashed"></Divider>
      <br/>
      <Radio.Group
        value={patientSectionValue}
        onChange={setPatientSectionValue}
        label='Select OR Create a Patient'
      >
        <Group mt='xs'>
          <Radio value="select" label="Select Existing Patient" disabled={ghettoPatient != undefined}/>
          <Radio value="create" label="Create New Patient" disabled={ghettoPatient != undefined}/>
        </Group>
      </Radio.Group>
      { patientSectionValue == 'select' ? <>
        <Select
            mt={8}
            withAsterisk
            label="Patient"
            placeholder="Select Patient"
            {...getInputProps('patient_id')}
            {...patientSelectProps}
            style={{ maxWidth: 300 }}
            searchValue={ghettoPatient || undefined}
            disabled={ghettoPatient != undefined}
        />
      </> : <>
        <TextInput
          mt={8}
          label="Name"
          withAsterisk
          placeholder="Name"
          {...patientCreateGetInputProps('name')}
          style={{ maxWidth: 300 }}
        />
        <TextInput
          mt="md"
          label="Date Of Birth (MM-DD-YY)"
          withAsterisk
          placeholder="Date Of Birth"
          {...patientCreateGetInputProps("date_of_birth_user_entered")}
          style={{ maxWidth: 300 }}
        />
        <Textarea
          mt={8}
          label="Patient Info"
          placeholder="Patient Info"
          {...patientCreateGetInputProps('patient_info')}
          minRows={4}
          style={{ maxWidth: 300 }}
        />
        <Select
            mt={8}
            label="Client"
            withAsterisk
            placeholder="Client"
            {...patientCreateGetInputProps('client_id')}
            style={{ maxWidth: 300 }}
            searchable={true}
            clearable={true}
            filterDataOnExactSearchMatch={true}
            data={filteredClients as any}
        />
        {/* @ts-ignore */}
        <Button {...patientCreateSaveButtonProps}>
          Create Patient
        </Button>
      </> }
    </>;

    // maybe part 1 of ghetto fix to fill select with newly created provider
    const [ ghettoReferringProvider, setGhettoReferringProvider ] = useState(null);

    const [ ghettoBillingProvider, setGhettoBillingProvider ] = useState(null);

    // useForm for Provider Creation
    // const {
      // values: providerCreateValues,
      // getInputProps: providerCreateGetInputProps,
      // saveButtonProps: providerCreateSaveButtonProps,
      // reset: resetOfproviderCreate,
      // setFieldValue: setFieldValueUponproviderCreate
    // } = useForm({
    //   initialValues: {
    //     // client_id: '',
    //     // date_of_birth_user_entered: ''
    //   },
    //   refineCoreProps: {
        // onMutationSuccess({data}, variables, context) {
        //   //set field upon referral of patient ID
        //   const { id: provider_id, name } = data;
        //   setFieldValue('provider_id', provider_id);
        //   values.patient_id = provider_id;

        //   setGhettoProvider(name);

        //   // mutate some UI
        //   setProviderSectionValue('select');

        //   // move to next step
        //   gotoStep(2)
        // },
    //     action: "create",
    //     resource: 'provider',
    //     redirect: false,
    //     metaData: {
    //       fields: [
    //         'id',
    //         'name'
    //         // maybe more
    //       ]
    //     }
    //   },
    //   validate(values) {
    //     const {
    //     } = values;

    //     return {
    //     }
    //   },
    //   // @ts-ignore
    //   transformValues(values) {
    //     let {
    //       ...rest
    //     } = values;

    //     return rest;
    //   },
    // });

    const {
      steps: { 
        currentStep: currentStepOfProviderCreate, 
        gotoStep: gotoStepOfProviderCreate 
      },
      insertListItem: insertListItemOfProviderCreate,
      removeListItem: removeListItemOfProviderCreate,

      values: providerCreateValues,
      getInputProps: providerCreateGetInputProps,
      saveButtonProps: providerCreateSaveButtonProps,
      reset: resetOfproviderCreate,
      setFieldValue: setFieldValueUponproviderCreate
  } = useStepsForm({
      initialValues: {
          // ...filteredInitialValues,
          provider_representatives: {
              data: []
          },
          addresses: [],
          contacts: [],
          client_id
      },
      refineCoreProps: {
          action: 'create',
          redirect: false,
          resource: 'provider',
          metaData:{
              fields: [
                  'id',
                  'name'
              ]
          },
          onMutationSuccess({data}, variables, context) {
            //set field upon referral of patient ID
            const { id: provider_id, name } = data;
            setFieldValue('provider_id', provider_id);
            values.patient_id = provider_id;
  
            setGhettoReferringProvider(name);
  
            // mutate some UI
            setReferringProviderSectionValue('select');
  
            // move to next step
            gotoStep(2)
          },
      },
      // @ts-ignore
      validate: (values) => {
          const {
              // @ts-ignore
              name,
              provider_representatives:{
                  data
              },

          } = values;

          if (currentStepOfProviderCreate === 0) {
              return {
                  name: name?.length ? null : "Please enter a name."
              }
          } else if (currentStepOfProviderCreate === 1){
              return {}
          } else if (currentStepOfProviderCreate === 3){
              const hasValidReps = data.filter( 
                  ({representative_id}) => representative_id
              ).length > 0
              return {
                  data: data?.length && hasValidReps ? null : "Please select a rep for this provider."
              }
          }
      }
  });

const {
  data: {
    // @ts-ignore
    data: representativeLookup
  } = {}
} = useList({
  resource: 'representative',

  meta:{
    fields: [ 'id', 'name'],
  },

  pagination: {
    pageSize: 1000
  }
});

const representatives = providerCreateValues.provider_representatives.data.map( (item, index) => (
    <Group key={index} mt="xs">
        <Select
            mt={8}
            label="Representative"
            placeholder="Select rep"
            {...providerCreateGetInputProps(`provider_representatives.data.${index}.representative_id`)}
            searchable={true}
            clearable={true}
            filterDataOnExactSearchMatch={true}
            data={representativeLookup?.map(({id,name}) => ({value:id, label:name})) as any}
            style={{ maxWidth: 300 }}
        />
        <ActionIcon color="red" onClick={() => removeListItemOfProviderCreate('provider_representatives.data', index)}>
            {/* @ts-ignore */}
            <CloseButton size="1rem" />
        </ActionIcon>
    </Group>
))

  const newCreateForm = <>
    <Stepper
        active={currentStepOfProviderCreate}
        onStepClick={gotoStepOfProviderCreate}
        breakpoint="sm"
    >
        <Stepper.Step
            label="First Step"
            description="Provider"
            // allowStepSelect={currentStep > 0}
        >
            <TextInput
                mt={8}
                label="Name"
                withAsterisk
                placeholder="Name"
                {...providerCreateGetInputProps('name')}
                style={{ maxWidth: 300 }}
            />
            <TextInput
                mt={8}
                label="Specialty"
                placeholder="Specialty"
                {...providerCreateGetInputProps('specialty')}
                style={{ maxWidth: 300 }}
            />
            <TextInput
                mt={8}
                label="NPI"
                placeholder="NPI"
                {...providerCreateGetInputProps('npi')}
                style={{ maxWidth: 300 }}
            />
            <TextInput
                mt={8}
                label="Extension Direct Send"
                placeholder="Extension Direct Send"
                {...providerCreateGetInputProps('ext_direct_send')}
                style={{ maxWidth: 300 }}
            />
            <TextInput
                mt={8}
                label="Survey Link"
                placeholder="Survey Link"
                {...providerCreateGetInputProps('survey_link')}
                style={{ maxWidth: 300 }}
            />
            <TextInput
                mt={8}
                label="Survey Direct Send"
                placeholder="Survey Direct Send"
                {...providerCreateGetInputProps('survey_direct_send')}
                style={{ maxWidth: 300 }}
            />
        </Stepper.Step>
        <Stepper.Step
            label="Second Step"
            description="Contact"
        >
            <Textarea
              mt={8}
              label="Address"
              placeholder="Address"
              {...providerCreateGetInputProps('specialty')}
              minRows={4}
              style={{ maxWidth: 300 }}
            />
            <TextInput
              mt={8}
              label="Phone"
              style={{ maxWidth: 300 }}
              {...providerCreateGetInputProps('phone')}
            />
            <TextInput
              mt={8}
              label="Fax"
              style={{ maxWidth: 300 }}
              {...providerCreateGetInputProps('fax')}
            />
            <Textarea
              mt={8}
              label="Office Contact"
              style={{ maxWidth: 300 }}
              {...providerCreateGetInputProps('office_contact')}
              minRows={2}
            />
            <Textarea
              mt={8}
              label="Other Address"
              placeholder="Other Address"
              {...providerCreateGetInputProps('specialty')}
              minRows={4}
              style={{ maxWidth: 300 }}
            />
        </Stepper.Step>
        <Stepper.Step
            label="Third Step"
            description="Notes"
        >
            <Textarea
              mt={8}
              label="Notes"
              placeholder="Notes"
              {...providerCreateGetInputProps('notes')}
              minRows={4}
              style={{ maxWidth: 300 }}
            />
        </Stepper.Step>
        <Stepper.Step
            label="Fourth Step"
            description="Rep(s)"
        >
            {representatives.length > 0 ? (
                <Group mb="xs">
                <Text weight={500} size="sm" 
                // sx={{ flex: 1 }}
                >
                    Rep(s)
                </Text>
                </Group>
            ) : (
                <Text color="dimmed" align="center">
                No Reps assigned.  Please select at least one to continue.
                </Text>
            )}

            {representatives}

            <Group mt="md">
                <Button
                onClick={() =>
                  insertListItemOfProviderCreate('provider_representatives.data', { representative_id : undefined })
                }
                >
                Add Representative
                </Button>
            </Group>
            <br/>
            <br/>
            {/* @ts-ignore */}
            <Button {...providerCreateSaveButtonProps}>
              Save Provider
            </Button>
        </Stepper.Step>
    </Stepper></>

    // Select Existing Patient OR
    // Create a New Patient
    const [ referringProviderSectionValue, setReferringProviderSectionValue ] = useState('select');
    const referringProviderSection = <>
      <Radio.Group
        value={referringProviderSectionValue}
        onChange={setReferringProviderSectionValue}
        label='Select OR Create a Provider'
      >
        <Group mt='xs'>
          <Radio value="select" label="Select Existing Provider" disabled={ghettoReferringProvider != null}/>
          <Radio value="create" label="Create New Provider" disabled={ghettoReferringProvider != null}/>
          <Radio value="unknown" label="Provider is Unknown" disabled={ghettoReferringProvider != null}/>
        </Group>
      </Radio.Group>
      { referringProviderSectionValue == 'select' && <Select
            mt="md"
            label="Provider"
            placeholder="Provider"
            {...getInputProps("provider_id")}
            {...providerProps}
            style={{ maxWidth: 300 }}
            searchValue={ghettoReferringProvider ? ghettoReferringProvider : undefined}
            disabled={ghettoReferringProvider != null}
        />}
      { referringProviderSectionValue == 'create' && newCreateForm }
    </>;

    const [ billingProviderSectionValue, setBillingProviderSectionValue ] = useState('select');
    useEffect( () => {
      if (
        referringProviderSectionValue != 'unknown' && 
        values?.provider_id &&
        billingProviderSectionValue == 'copy'
      ){
        setFieldValue('billing_provider_id', values?.provider_id);
      }
    }, [referringProviderSectionValue, billingProviderSectionValue, values?.provider_id]);

    useEffect( () => {
      if (['create','unknown'].includes(referringProviderSectionValue)){
        setFieldValue('provider_id', null);
      }
    }, [referringProviderSectionValue]);
    useEffect( () => {
      if (['create','unknown'].includes(billingProviderSectionValue)){
        setFieldValue('billing_provider_id', null);
      }
    }, [billingProviderSectionValue]);

    const billingProviderSection = <>
    <Radio.Group
      value={billingProviderSectionValue}
      onChange={setBillingProviderSectionValue}
      label='Select OR Create a Provider'
    >
      <Group mt='xs'>
        { !!values?.provider_id && <Radio
          value="copy" label="Same as Referring Provider" disabled={ghettoBillingProvider != null}
        />}
        <Radio value="select" label="Select Existing Provider" disabled={ghettoBillingProvider != null}/>
        <Radio value="create" label="Create New Provider" disabled={ghettoBillingProvider != null}/>
        <Radio value="unknown" label="Provider is Unknown" disabled={ghettoBillingProvider != null}/>
      </Group>
    </Radio.Group>
    { billingProviderSectionValue == 'select' && <Select
          mt="md"
          label="Billing Provider"
          placeholder="Billing Provider"
          {...getInputProps("billing_provider_id")}
          {...providerProps}
          style={{ maxWidth: 300 }}
          searchValue={ghettoBillingProvider ? ghettoBillingProvider : undefined}
          disabled={ghettoBillingProvider != null}
      />}
    { billingProviderSectionValue == 'create' && newCreateForm }
    </>;

    // maybe part 1 of ghetto fix to fill select with newly created patient
    const [ ghettoInsurance, setGhettoInsurance ] = useState(null);

    // useForm for Insurance Creation
    const {
      getInputProps: insuranceCreateGetInputProps,
      saveButtonProps: insuranceCreateSaveButtonProps
    } = useForm({
      initialValues: {
        title: ''
      },
      refineCoreProps: {
        action: 'create',
        resource: "insurance",
        redirect: false,
        metaData: {
          fields: [
            'id',
            'title'
          ]
        },
        onMutationSuccess({data}, variables, context) {
          const { id: insuranceId, title } = data;
          setFieldValue('insurance_id', insuranceId);
          values.insurance_id = insuranceId;

          setGhettoInsurance(title)

          // TODO make UI show created record in Select List automatically
          setInsuranceSectionValue('select');
          gotoStep(4);
        },
      },
      validate(values) {
          const { title } = values;

          return {
            title: title.length > 2 ? null : 'Please enter a title.'
          }
      },
      // @ts-ignore
      transformValues(values){
          values.title = values.title.toUpperCase();
          return values;
      }
    })

    // TODO add provider associate multiple reps

    // Select Existing Insurance OR
    // Create a New Insurance
    const [ insuranceSectionValue, setInsuranceSectionValue ] = useState('select');
    const insuranceSection = <>
      <Select
          mt={8}
          label="Insurance Status"
          // placeholder="Client"
          {...getInputProps('insurance_status_id')}
          {...insuranceStatusSelectProps}
          style={{ maxWidth: 300 }}
      />
      <br/>
      <Divider variant="dashed"></Divider>
      <br/>
      <Radio.Group
        value={insuranceSectionValue}
        onChange={setInsuranceSectionValue}
        label='Select OR Create a Insurance'
      >
        <Group mt='xs'>
          <Radio value="select" label="Select Existing Insurance" disabled={ghettoInsurance != null}/>
          <Radio value="create" label="Create New Insurance" disabled={ghettoInsurance != null}/>
        </Group>
      </Radio.Group>
      { insuranceSectionValue == 'select' ? <>
        <Select
            mt={8}
            label="Insurance"
            placeholder="Select Insurance"
            {...getInputProps('insurance_id')}
            {...insuranceProps}
            style={{ maxWidth: 300 }}
            searchValue={ghettoInsurance ? ghettoInsurance : undefined}
            disabled={ghettoInsurance != null}
        />
      </> : <>
        <TextInput
          mt={8}
          label="Insurance"
          placeholder="Insurance"
          {...insuranceCreateGetInputProps('title')}
          style={{ maxWidth: 300 }}
        />
        {/* @ts-ignore */}
        <Button {...insuranceCreateSaveButtonProps}>
          Create Insurance
        </Button>
      </>}
    </>

    return (
        <Create saveButtonProps={saveButtonProps}
          footerButtons={
                <Group position="right" mt="xl">
                    {currentStep !== 0 && (
                        <Button
                            variant="default"
                            onClick={() => gotoStep(currentStep - 1)}
                        >
                            Back
                        </Button>
                    )}
                    {currentStep !== 5 && (
                        <Button onClick={() => gotoStep(currentStep + 1)}>
                            Next step
                        </Button>
                    )}
                    {currentStep === 5 && <
                      // @ts-ignore
                      SaveButton {...saveButtonProps} />}
                </Group>
            }
        >
            <Stepper
                active={currentStep}
                onStepClick={gotoStep}
                breakpoint="sm"
            >
                <Stepper.Step
                    label="First Step"
                    description="Patient"
                    // allowStepSelect={currentStep > 0}
                >
                  {patientSection}
                </Stepper.Step>

                <Stepper.Step
                    label="Second Step"
                    description="Rx"
                    // allowStepSelect={currentStep > 2}
                >
                    <Select
                        mt="md"
                        label="Product Type*"
                        placeholder="Product Type"
                        {...getInputProps("product_type_id")}
                        {...productTypeProps}
                        style={{ maxWidth: 300 }}
                    />
                    <TextInput
                      mt="md"
                      label="RX"
                      disabled
                      {...getInputProps("product_title")}
                      style={{ maxWidth: 300 }}
                    />
                    <DatePicker
                        mt="md"
                        label="Date of Rx"
                        placeholder="Date of Rx"
                        withinPortal
                        {...getInputProps("rx_date")}
                        value={dayjs(values.rx_date as Date) as any}
                        style={{ maxWidth: 300 }}
                    />
                    <DatePicker
                        mt="md"
                        label="Date Rx REC'D"
                        placeholder="Date Rx REC'D"
                        withinPortal
                        {...getInputProps("rx_received_date")}
                        value={dayjs(values.rx_received_date as Date) as any}
                        style={{ maxWidth: 300 }}
                    />
                    <Select
                        mt="md"
                        label="Rx Length"
                        placeholder="Rx Length"
                        {...getInputProps("rx_length_id")}
                        {...rxLengthProps}
                        style={{ maxWidth: 300 }}
                    />
                    <br/>
                    <br/>

                    <Grid>
                        <Grid.Col span={6}>
                            {injurySites.length > 0 ? (
                                <Group mb="xs">
                                <Text weight={500} size="sm" 
                                // sx={{ flex: 1 }}
                                >
                                    Injury Site(s)
                                </Text>
                                </Group>
                            ) : (
                                <Text color="dimmed" align="center">
                                No injury sites defined
                                </Text>
                            )}

                            {injurySites}

                            <Group mt="md">
                                <Button
                                onClick={() =>
                                    insertListItem('injury_sites', '')
                                }
                                >
                                Add Another Injury Site
                                </Button>
                            </Group>
                        </Grid.Col>
                    </Grid>    
                    
                </Stepper.Step>

                <Stepper.Step
                    label="Third Step"
                    description="Referring Provider"
                    // allowStepSelect={currentStep > 1}
                >
                    {referringProviderSection}
                </Stepper.Step>
                <Stepper.Step
                    label="Fourth Step"
                    description="Billing Provider"
                    // allowStepSelect={currentStep > 1}
                >
                    {billingProviderSection}
                </Stepper.Step>
                <Stepper.Step
                  label="Fifth Step"
                  description="Insurance"
                  >
                  {insuranceSection}
                </Stepper.Step>
                <Stepper.Step
                    label="Sixth Step"
                    description="Claim"
                    // allowStepSelect={currentStep > 3}
                    >
                    <Select
                        mt="md"
                        label="Claim Type"
                        placeholder="Claim Type"
                        {...getInputProps("claim_type_id")}
                        {...claimTypeProps}
                        style={{ maxWidth: 300 }}
                    />
                </Stepper.Step>
                <Stepper.Completed>
                    Completed! Form values:
                    <Space />
                    <Code mt="xl">{JSON.stringify(values, null, 2)}</Code>
                </Stepper.Completed>
            </Stepper>
            {AdminDebug(values)}
        </Create>
    );
};