import {
  HttpError,
  useResource,
  useNavigation,
  useOne,
  useGetIdentity,
  BaseKey,
  useList,
  useCustom,
} from "@refinedev/core";

import { useForm, useSelect, EditButton } from "@refinedev/mantine";
import { createStyles } from "@mantine/styles";
import { Notes } from "components/notes";

import { IconPencil, IconX, IconCheckbox } from "@tabler/icons"

import {
  Select,
  Table,
  Tabs,
  Textarea,
  TextInput,
  Text,
  Avatar,
  Group,
  Paper,
  Button,
  Stack,
  ActionIcon,
  CloseButton,
  Radio,
  Divider,
  Grid,
  Switch,
  Chip,
  Anchor
} from "@mantine/core";

import { Edit as SwizzledEdit } from 'components/crud/edit'
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Create as SwizzledCreate } from 'components/crud/create'

import { extended_fields, resource } from './common'
import { DatePicker } from "@mantine/dates";
import { datePickerValue, isAffectrixClientId, isVenousClientId } from "utilities";

export const SubmissionEdit: React.FC = () => {

  // return <>Edit ME</>
  const formatDate = (x: any) => dayjs(x).format('MM-DD-YY');

  const { id } = useResource();

  const { list } = useNavigation();

  const {
    data: {
      // @ts-ignore
      claims: {
        "x-hasura-user-id": uid ='',
        "x-hasura-client-id": clientId =''
      } = {}
    } = {}
  } = useGetIdentity({
  });

  const isUserOfAffectrix = isAffectrixClientId(clientId);
  const isUserOfVenous = isVenousClientId(clientId);

  const {
		data: {
			data: initialValues = {}
		} = {}
	} = useOne<any>({
		resource,
		id,
		meta: {
      fields: [
        ...extended_fields,
        // ...specificFields
      ]
		},
	});

  let automationRan = true;
  if (initialValues?.autocrat_job_status){
    const bool0 = initialValues?.autocrat_job_status?.shipment_request || initialValues?.autocrat_job_status?.delivery_ticket;
    // const bool1 = Object.keys(initialValues?.autocrat_job_results).length > 0;
    automationRan = !!bool0;
  }

  const questions = [
    "ATTORNEY", "COMMERCIAL INSURANCE",
    "DOI", "EMPLOYER", "NON-COMMERCIAL INSURANCE CARRIER"
  ];

  const {
    data: {
      data: template_questions = []
    } = {}
  } = useList({
    resource: 'template_question',
    meta: {
      fields: [ 'id', 'title', 'field' ],
    },
    filters: [
      {
        field: 'title',
        operator: 'in',
        value: questions
      }
    ],
    sorters: [
      {
        field: 'title',
        order: 'asc'
      }
    ],
    queryOptions: {
      staleTime: 60_000 * 5
    },
    pagination: {
      pageSize: 1000
    }
  });

  const referral_id = initialValues?.referral_id;
  const answer_sets = initialValues?.referral?.patient?.answer_sets || [];

  // @ts-ignore
  const questionnaireObj = answer_sets.reduce( (prev, curr) => {
    const {
      views,
      answers
    } = curr;

    // this answer set has ref_id AND initial
    const boolean = views.some( (view:any) => {
      const { referrals } = view;
      return (
        referrals.some( (some_ref_id:string) => some_ref_id == referral_id)
      )
    })

    if (boolean){
      // @ts-ignore
      return template_questions.map(q => {
        const { id: qId } = q;
        
        return {
          ...q,
          value: answers[qId as string] || ''
        }
      })
      // @ts-ignore
      .sort(({title:a},{title:b}) => {
        if (a<b) return -1;
        if (a>b) return 1;
        return 0;
      } )
    }
    return prev
  }, [])

  const target = initialValues?.submission_type;
  const onMutationSuccess = (data: any, variables: any, context: any) => {
    if (target == 'delivery_ticket'){
      list(`fulfillment/deliveryTicket`);
    } else if (target == 'shipment_request'){
      list(`fulfillment/shipmentRequest`);
    }
  }

  const main = useForm< HttpError>({
    refineCoreProps: {
      // warnWhenUnsavedChanges: true,
        resource,
        id,
        metaData: {
            fields: [
              ...extended_fields,
              // ...specificFields
            ]
        },
        onMutationSuccess,
        redirect: false,
        autoSave: {
          enabled: true,
        }
    },
    initialValues: {
      items: [],
      product_id: null,
      // isRental: 'YES',
      isExisting: 'YES',
      ...initialValues,
    },
    transformValues: (values) => {
        // @ts-ignore
        const { submission_type } = values;
        // @ts-ignore
        const product_type = values?.referral?.product_type?.title;

        if (submission_type == 'shipment_request'){

          if (product_type != 'TCT'){
            const {
              fitter_id,
              // DOI,  template  TODO
              // EMPLOYER, template WC only
              // ATTORNEY, tempate
              ...rest
            } = values;
            // @ts-ignore
            values = rest;
          }
          
        }
        if (submission_type == 'delivery_ticket'){
          const {
            date_shipped,
            tracking_number,
            ...rest
          } = values;
          // @ts-ignore
          values = rest;
        }

        const { id, product, referral, fitter, isExisting, ...rest } = values;
        values = rest;

        return values
    },
});

const {
  saveButtonProps,
  refineCore: { queryResult, onFinish, formLoading, redirect },
  getInputProps,
  values,
  insertListItem,
  removeListItem,
  setFieldValue
} = main;

const anchor = values?.product_id && <Anchor 
  href={`/fulfillment/product/edit/${values?.product_id}`}
  target="_blank">
  Link to Equipment
</Anchor>;

const [ created, setCreated ] = useState(false);

// create new equipment
const createEquipmentForm = useForm< HttpError>({
  refineCoreProps: {
    // warnWhenUnsavedChanges: true,
      resource: 'product',
      action: 'create',
      id,
      metaData: {
          fields: [
            'id',
          ]
      },
      onMutationSuccess: ({data}) => {
        setFieldValue('product_id', data?.id);
        setCreated(true);
      },
      onMutationError(error, variables, context, isAutoSave) {
        console.log(error.message)
      },
      redirect: false,
      // autoSave: {
      //   enabled: true,
      // }
  },
  transformValues: (values) => {
    
    return values
  },
});

const [ serialNumInit, setSerialNumInit ] = useState(false);

useEffect( () => {
  const product_type_id = initialValues?.referral?.product_type_id;
  if (product_type_id){
    createEquipmentForm.setFieldValue('product_type_id', product_type_id);
  }

  const has_serial_number = initialValues?.serial_number || initialValues?.product_id;
  if (has_serial_number){
    setSerialNumInit(true);
  }
}, [initialValues]);

const { selectProps: productSelectProps } = useSelect({
  resource: "product",
  optionLabel: 'serial_number',

  // @ts-ignore
  defaultValue: values?.product_id || undefined,

  meta: {
      fields: ["id", "serial_number"],
      
  },

  filters: [
    {
      field: 'patient_id',
      operator: 'null',
      value: true
    },
    {
      field: 'equipment_status.title',
      operator: 'eq',
      value: 'AVAILABLE'
    },
    {
      field: 'product_type_id',
      operator: 'eq',
      // @ts-ignore
      value: values?.referral?.product_type_id
    },
    // {
    //   field: 'location_id',
    //   operator: 'eq',
    //   value: values?.location_id
    // }
  ],

  queryOptions: {
    // @ts-ignore
    enabled: !!values?.referral?.product_type_id
  },

  pagination: {
    mode: "server"
  }
});

  const { selectProps: fitterSelectProps } = useSelect({
    resource: "fitter",
    optionLabel: 'title',

    meta: {
        fields: ["id", "title"],
    },

    filters: [
      {
        field: 'title',
        operator: 'contains',
        // @ts-ignore
        value: (target == 'shipment_request' && values?.referral?.product_type?.title == 'TCT') ? "VIRTUAL FITTING" : ""
      }
    ],

    pagination: {
      mode: "server"
    }
  });

  const { selectProps: locationSelectProps } = useSelect({
    resource: 'location',
    optionLabel: 'title',

    meta: {
      fields: [ 'id', 'title' ],
    },

    filters: [
      {
        field: 'client_id',
        operator: 'null',
        value: false
      },
      {
        field: 'code',
        operator: 'in',
        value: [ "AFFECTRIX", "PITT", "COLORADO HUB" ]
      }
    ],

    pagination: {
      mode: "server"
    }
  })

  const {
    data: {
      // @ts-ignore
      data: itemLookup
    } = {}
  } = useList({
    resource: 'selection',

    meta:{
      fields: [ 'id', 'title'],
    },

    pagination: {
      pageSize: 1000
    }
  });

  const product_type_array = ['TCT','NMES','SCOOTER','BRACING', 'BONE STIM', 'STIM'];
  const is_rentable = [ 'TCT', 'BONE STIM', 'NMES', 'TENS' ];

  const runArray = [
    {
      value: 'SHIPMENT REQUEST',
      // product_type: ['TCT','NMES','SCOOTER','BRACING'],
      product_type: product_type_array,
      submission_type: 'shipment_request'
    },
    // {
    //   value: 'NMES SHIPMENT REQUEST',
    //   product_type: ['NMES'],
    //   submission_type: 'shipment_request'
    // },
    // {
    //   value: 'TCT CO HUB',
    //   product_type: ['TCT'],
    //   submission_type: 'shipment_request'
    // },
    // {
    //   value: 'KS CO HUB',
    //   product_type: ['SCOOTER'],
    //   submission_type: 'shipment_request'
    // },
    {
      value: 'DELIVERY TICKET',
      // product_type: ['TCT','NMES','SCOOTER','BRACING'],
      product_type: product_type_array,
      submission_type: 'delivery_ticket'
    },
    // {
    //   value: 'NMES DELIVERY TICKET',
    //   product_type: ['NMES'],
    //   submission_type: 'delivery_ticket'
    // }
  ]
  // @ts-ignore
  .filter( x => {
    const {
      product_type, submission_type
    } = x;
    const boolean = product_type.includes(
      // @ts-ignore
      values?.referral?.product_type?.title
    ) && submission_type == values?.submission_type
    return boolean
  })
  ?.map(({value}) => ({value, label:value}))

  // console.log(runArray)

   // @ts-ignore
   const itemsOfSubmissionForm = values.items.map( (itemId, index) => {

    return (<Group key={index}>
      <Divider my="xs" label={`Item #${index+1}`} />
      {/* TODO when moving logic of referral view to patient-referrals view, consider below */}
      {/* <Select
        mt={8}
        label="Product Type"
        {...getInputPropsForSubmission(`items.${index}.product_type`)}
        {...productTypeSelectProps2}
        style={{ maxWidth: 300 }}
      /> */}
      {/* @ts-ignore */}
      {/* {['SCOOTER','TCT'].includes(submissionValues?.items[index]?.product_type) && <>
        Product Lookup
      </>}  */}
      <Select
          mt={8}
          // label="Item"
          placeholder="Search for an Item."
          {...getInputProps(`items.${index}`)}
          searchable={true}
          clearable={true}
          filterDataOnExactSearchMatch={true}
          data={itemLookup?.map(({id,title}) => ({value:id, label:title})) as any}
          style={{ maxWidth: 300 }}
      />
      <ActionIcon color="red" onClick={() => removeListItem('items', index)}>
        <CloseButton 
        // @ts-ignore
        size="1rem" />
      </ActionIcon>
    </Group>)
  })

  let labelForSubmission;
  switch (target){
    case 'shipment_request':
      labelForSubmission = 'Shipment Request';
      break;
    case 'delivery_ticket':
      labelForSubmission = 'Delivery Ticket';
      break;
  }

  const {
    saveButtonProps: referralSaveButtonProps,
    getInputProps: getInputPropsForReferral,
    values: referralValues,
    // refineCore: {
    //   setId: setIdOfReferralForm
    // }
  } = useForm({
    initialValues: {
      // @ts-ignore
      date_fit: values?.referral?.date_fit
    },
    refineCoreProps: {
      // @ts-ignore
      id: values?.referral?.id,
      resource: 'referral',
      action: 'edit',
      redirect: false,
      metaData: {
        fields: [ 'id' ]
      }
    }
  })

  useEffect( () => {
    // @ts-ignore
    referralValues.date_fit = initialValues?.referral?.date_fit
  }, [initialValues]);

  // initialValues.submission_type
  let canRunJob = false;
  if (initialValues.submission_type == 'shipment_request'){
    canRunJob = true;
  } else if (initialValues.submission_type == 'delivery_ticket') {
    canRunJob = true; // tODO
  }

  const fieldsOfSubission = <>
    <Select
      mt={8}
      label="URGENCY"
      {...getInputProps('urgency')}
      style={{ maxWidth: 300 }}
      data={[
        {value:'SURGICAL', label:'SURGICAL'},
        {value:'IMEDIATE', label:'IMEDIATE'},
        {value:null as any, label:'-/-'}
      ]}
    />
    <TextInput
      mt={8}
      label="Patient"
      {...getInputProps('referral.patient.name_and_dob')}
      style={{ maxWidth: 300 }}
    />
    <Textarea
      mt={8}
      label="Patient Info"
      {...getInputProps(`referral.patient.patient_info`)}
      minRows={4}
      style={{ maxWidth: 300 }}
    />
    <DatePicker
        mt="md"
        label="DATE FIT"
        withinPortal
        {...getInputPropsForReferral(`date_fit`)}
        value={datePickerValue(referralValues)(`date_fit`)}
        style={{ maxWidth: 300 }}
    />
    {/* @ts-ignore */}
    <Button {...referralSaveButtonProps}>Update DATE FIT</Button>
    { target == 'shipment_request' && <>
      
      
      <Select
        mt={8}
        label="SHIPPER"
        {...getInputProps('shipper')}
        style={{ maxWidth: 300 }}
        data={[
          {value:'FEDEX', label:'FEDEX'},
          {value:'USPS', label:'USPS'},
          {value:null as any, label:'-/-'}
        ]}
      />
      <DatePicker
            mt="md"
            label="DATE SHIPPED"
            withinPortal
            {...getInputProps(`date_shipped`)}
            value={datePickerValue(values)(`date_shipped`)}
            style={{ maxWidth: 300 }}
        />
      <TextInput
          mt={8}
          label="TRACKING #"
          // type="date"
          // placeholder="Serial Number"
          {...getInputProps("tracking_number")}
          style={{ maxWidth: 300 }}
      />

      { !serialNumInit ? <>
        {/* is rental */}
        <Radio.Group label="Is this a rental?" {...getInputProps('isRental')}>
          <Radio value="YES" label="YES"/>
          <Radio value="NO" label="NO"/>
        </Radio.Group>

        {/* @ts-ignore */}
        { values?.isRental == 'YES' ? <>
        
          {/* @ts-ignore */}
          { is_rentable.includes(values?.referral?.product_type?.title) ? <>
            {/* is existing */}
            {/* @ts-ignore */}
            <Radio.Group label={`EITHER Lookup an existing ${values?.referral?.product_type?.title} OR create a new one.`} {...getInputProps('isExisting')}>
              <Radio value="YES" label="LOOKUP"/>
              <Radio value="NO" label="CREATE"/>
            </Radio.Group>

            { values?.isExisting == 'YES' ? <>
              <Select
                mt={8}
                label="SERIAL #"
                {...getInputProps('product_id')}
                style={{ maxWidth: 300 }}
                {...productSelectProps}
              />
              {anchor}
            </> : <>
              { !created ? <>
                <TextInput
                  mt={8}
                  label="Enter in a SERIAL # for this new unit"
                  // type="date"
                  // placeholder="Serial Number"
                  {...createEquipmentForm.getInputProps("serial_number")}
                  style={{ maxWidth: 300 }}
                />
                <Button
                  // @ts-ignore
                  {...createEquipmentForm.saveButtonProps}
                >
                  { created ? 
                  // @ts-ignore
                  `CREATE ${values?.referral?.product_type?.title}` :
                  // @ts-ignore 
                  `CREATED `}
                </Button>
              </> : <TextInput
                  mt={8}
                  disabled
                  label="SERIAL #"
                  // type="date"
                  // placeholder="Serial Number"
                  {...createEquipmentForm.getInputProps("serial_number")}
                  style={{ maxWidth: 300 }}
                />}
              
            </>}
            
          </> : <>
            <TextInput
              mt={8}
              label="SERIAL #"
              // type="date"
              // placeholder="Serial Number"
              {...getInputProps("serial_number")}
              style={{ maxWidth: 300 }}
            />
          </>}

        </> : <TextInput
          mt={8}
          label="SERIAL #"
          // type="date"
          // placeholder="Serial Number"
          {...getInputProps("serial_number")}
          style={{ maxWidth: 300 }}
        />}  
      </> : <>
        {!values?.serial_number ? <>
          <>
            <Select
              mt={8}
              label="SERIAL #"
              {...getInputProps('product_id')}
              style={{ maxWidth: 300 }}
              {...productSelectProps}
            />
            {anchor}
          </>
        </> : <>
           <TextInput
              mt={8}
              label="SERIAL #"
              // type="date"
              // placeholder="Serial Number"
              {...getInputProps("serial_number")}
              style={{ maxWidth: 300 }}
          />
        </>}
      </>}

      

      <Select
        mt={8}
        label="RECEIVED"
        {...getInputProps('received')}
        style={{maxWidth: 300}}
        data={[
          {value:'YES', label:'YES'},
          {value:'RETURNED', label:'RETURNED'},
          {value:'WRONG ADDRESS', label:'WRONG ADDRESS'},
          {value:null as any, label:'-/-'}
        ]}
      />
    </> }
    {/* @ts-ignore */}
    { (target == 'delivery_ticket' || ( target == 'shipment_request' && values?.referral?.product_type?.title == 'TCT')) && <>
      <Select
        mt={8}
        label="FITTER"
        {...getInputProps('fitter_id')}
        style={{ maxWidth: 300 }}
        {...fitterSelectProps}
        // dasubmissionValues.submission_typeta={productSelectProps.data}
      />
    </>}
    {/* @ts-ignore */}
    { ['TCT','NMES','SCOOTER','BRACING'].includes(values?.referral?.product_type?.title) &&
      target == 'shipment_request' &&
     <Select
        mt={8}
        label="Ship From"
        // placeholder="Client"
        {...getInputProps('from_location_id')}
        {...locationSelectProps}
        style={{ maxWidth: 300 }}
    />}
    <Select
        mt={8}
        label="WHICH JOB TO RUN"
        {...getInputProps(`run`)}
        searchable={true}
        clearable={true}
        filterDataOnExactSearchMatch={true}
        // defaultValue={itemId}
        data={runArray}
        // value={matchedTitle}
        style={{ maxWidth: 300 }}
        disabled={automationRan}
    />
    {automationRan && <>Automation already ran<br/></>}
    <br/>
    <Text>Items*</Text>
    {itemsOfSubmissionForm}
    <Group mt="md">
        <Button
        onClick={() =>{
          insertListItem('items', '');
        }}
        >
          {/* @ts-ignore */}
        Add { itemsOfSubmissionForm.length > 0 ? 'another ' : ''} 
        {/* @ts-ignore */}
        Item to this {values?.referral?.product_type?.title} {labelForSubmission}
        </Button>
    </Group>
  </>

  const automations = <>
    {/* @ts-ignore */}
    {/* AUTOMATIONS for {values?.referral?.product_type?.title}
    
    SHIPMENT REQUEST (AFX, VS) * (TCT, MZ, SCOOTER) * (location{'{}'}) */}
    {/* @ts-ignore */}
    {/* { values?.referral?.product_type?.title == 'TCT' && <>
      
      TCT CO HUB (VS)
      TCT SHIPMENT REQUEST (VS)
    </>} */}

    {/* <Select
        mt={8}
        label="Ship From"
        // placeholder="Client"
        {...getInputProps('location_id')}
        {...locationSelectProps}
        style={{ maxWidth: 300 }}
    />
    <Button>RUN {labelForSubmission}</Button> */}
    {/* <Switch size='lg' onLabel='SENT' offLabel='NOT' label='NEW' checked={values?.automated_email_status?.newReferral}/> */}
  </>;

  // @ts-ignore
  const referralsOfPatient = [{
    // @ts-ignore
    id: values?.referral?.id,
    // @ts-ignore
    product_type: values?.referral?.product_type,
    // @ts-ignore
    rx_received_date: values?.referral?.rx_received_date
  }];

  const forms = {
    main
  }

  const notesSection = <Notes
      // @ts-ignore
      patient_id={initialValues?.referral?.patient_id}
      forms={forms}
      referrals={referralsOfPatient}
      // @ts-ignore
      patient_notes={values?.referral?.patient?.patient_notes}
      main_accessor='referral.patient.patient_notes'
  />;

  return (
    <>
    <SwizzledEdit saveButtonProps={saveButtonProps} title={<Text fw={700}>
      Edit {initialValues?.referral?.product_type?.title} {labelForSubmission}
    </Text>}>
      <Tabs defaultValue={'main'}>
        <Tabs.List>
          <Tabs.Tab value='main'>Main</Tabs.Tab>
          <Tabs.Tab value='patient_notes'>Patient Notes</Tabs.Tab>
          {/* <Tabs.Tab value='automations'>Automations</Tabs.Tab> */}
        </Tabs.List>
        <Tabs.Panel value='main'>{ fieldsOfSubission }</Tabs.Panel>
        <Tabs.Panel value='patient_notes'>
          {notesSection}
        </Tabs.Panel>
        <Tabs.Panel value='automations'>{ automations }</Tabs.Panel>
      </Tabs>
      
    </SwizzledEdit>
    <pre>{JSON.stringify(questionnaireObj, null, 2)}</pre>
    <pre>{JSON.stringify(values, null, 2)}</pre>
    </>
  )
}